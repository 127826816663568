/**
 * Copyright.
 *
 * @format
 */
/* eslint-disable max-len */

import React from 'react';
import { safeTranslate } from '../../../services/drupal-services';

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  const allRightsReserved = safeTranslate('All Rights reserved.');
  const copyrightText = safeTranslate(
    'Production of any of the material contained herein in any format or media without the express written permission of Varian Medical Systems is prohibited.'
  );
  return (
    <div className="copyright-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
            <p className="copyright-year">
              © 1999-{currentYear} Varian Medical Systems, Inc. &nbsp;
              {allRightsReserved}
            </p>
            <p>{copyrightText}</p>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-lg-offset-1">
            <div className="trust-e">
              <div id="consent_blackbar" />
              <div id="teconsent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
