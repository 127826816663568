/**
 * Marketo Forms.
 * @format
 */

/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */

const marketoCompanyId = '760-DZO-155';

/**
 * Setup Hidden Marketo Form.
 * @param {string} formId
 */
const setupHiddenMarketoForm = (formId) => {
  const marketoForm = document.createElement('form');
  marketoForm.id = `mktoForm_${formId}`;
  marketoForm.classList.add('marketo-hidden-form');
  document.body.appendChild(marketoForm);
};

/**
 * Get Form Fields.
 *
 * @param {string} formType
 */
const getFormFields = (form) => {
  const formFields = form.querySelectorAll('input, textarea, select');
  const formValues = {};
  if (formFields.length > 0) {
    [].forEach.call(formFields, (field) => {
      formValues[field.name] = field.value;
    });
  }
  return formValues;
};

/**
 * Submit Form to Marketo.
 *
 * @param {string} formId
 */
const submitFormToMarketo = (formId, formFields) => {
  MktoForms2.loadForm('//app-sj30.marketo.com', marketoCompanyId, formId);
  MktoForms2.whenReady((form) => {
    form.vals(formFields);
    form.onSuccess(() => {
      return false;
    });
    form.submit();
  });
  return false;
};

/**
 * Form Submission.
 *
 * @param {string} formId
 * @param {string} formType
 * @param {string} marketoFormId
 */
export const submitForm = (formId, formType, marketoFormId) => {
  const form = document.getElementById(formId);
  if (form) {
    const formFields = getFormFields(form);
    setupHiddenMarketoForm(marketoFormId);
    return submitFormToMarketo(marketoFormId, formFields);
  }
  return false;
};
