/** @format */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { safeUrl } from '../../services/drupal-services';

/**
 * Featured Menu Item.
 *
 * @param {string|undefined} description
 * @param {string|undefined} title
 * @param {string|undefined} image
 * @param {string|undefined} link
 * @param {string|undefined} linkText
 */
const FeaturedMenuItem = ({ description, title, image, link, linkText }) => {
  const featuredBlurb =
    description ||
    `Lorem ipsum dolor amet etsy irony fashion axe biodiesel brooklyn beard
  sustainable seitan prism truffaut woke affogato.`;
  const featuredTitle = title || 'Featured Title';
  const featuredLinkText = linkText || 'Featured Link';
  const featuredLink = link || '#';
  const featuredImage = image ? (
    <img src={image.embed_link} alt={featuredTitle} title={featuredTitle} />
  ) : null;

  return (
    <a href={safeUrl(featuredLink)}>
      <div className="featured-menu-item">
        <div className="featured-menu-image">{featuredImage}</div>
        <div className="featured-menu-title">{featuredTitle}</div>
        <div className="featured-menu-blurb">{featuredBlurb}</div>
        <span className="featured-link">{featuredLinkText}</span>
      </div>
    </a>
  );
};
export default FeaturedMenuItem;

/**
 * Featured Menu Item PropTypes.
 */
FeaturedMenuItem.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  linkText: PropTypes.string,
};
