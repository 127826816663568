/** @format */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Language Links.
 *
 * @param {object} languageList
 */
const LanguageLink = ({ path, linkItem }) => {
  const {
    language_display_text: translatedName,
    locale_language_code: id,
    locale_flag: flag,
  } = linkItem;
  const flagSource = `/themes/custom/varian/webpack/components/language-selector/images/flags/${flag}.png`;
  let languagePath = id === 'en' ? `${path}` : `/${id}${path}`;
  languagePath = languagePath === '' ? '/' : languagePath;
  return (
    <li hrefLang={id} data-drupal-link-system-path={path} key={id}>
      <a href={languagePath} hrefLang={id} data-drupal-link-system-path={path}>
        <img src={flagSource} alt={translatedName} />
        {translatedName}
      </a>
    </li>
  );
};
export default LanguageLink;

/**
 * Language Link PropTypes.
 */
LanguageLink.propTypes = {
  linkItem: PropTypes.object,
  path: PropTypes.string,
};
