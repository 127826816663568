/** @format */

import React from 'react';
import {
  safeTranslate,
  safeCurrentLanguage,
} from '../../../services/drupal-services';
import WeChatModal from './wechat.jsx';

const SocialFollow = () => {
  const translatedTitle = safeTranslate('Connect with Us');
  const currentLanguage = safeCurrentLanguage;
  const china = currentLanguage === 'zh-hans';

  return (
    <div className="social-follow">
      <div className="social-follow-title">{translatedTitle}</div>
      <div>
        {!china ? (
          <>
            <a
              href="https://www.facebook.com/VarianMedicalSystems"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://twitter.com/VarianMedSys"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-twitter" />
            </a>
          </>
        ) : (
          ''
        )}

        <a
          href="https://www.linkedin.com/company/varian-medical-systems/"
          target="_blank"
          rel="noopener noreferrer">
          <i className="fab fa-linkedin-in" />
        </a>

        {china ? <WeChatModal /> : ''}
      </div>
    </div>
  );
};
export default SocialFollow;
