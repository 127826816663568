/** @format */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import AuxilarayLink from './auxilary-link.jsx';

export const buildAuxilaryLinks = (links) => {
  if (links.length > 0) {
    return links.map((auxilaryLink) => {
      const { name, link, new_window: external } = auxilaryLink;
      return (
        <AuxilarayLink
          key={name}
          name={name}
          link={link}
          external={external !== '0'}
        />
      );
    });
  }
  return null;
};
