/** @format */

import React from 'react';
import { useStateValue } from '../../context/state-context';

/**
 * Hamburger Button.
 */
const HamburgerButton = () => {
  const [{ openToggle }, dispatch] = useStateValue();
  const openClass = openToggle.menuOpen ? 'open' : '';
  const closingClass = openToggle.closing ? 'closing' : '';
  const hamburgerClasses = `hamburger ${openClass} ${closingClass}`;
  const overlayClasses = `background-overlay ${openClass} ${closingClass}`;

  /**
   * Handle Menu Update.
   *
   * @param {boolean} open
   * @param {boolean} close
   */
  const handleMenuUpdate = (open, close) => {
    dispatch({
      type: 'toggleMenu',
      toggleOpen: { menuOpen: open, closing: close },
    });
  };

  /**
   * Handle Menu Toggle.
   */
  const handleMenuToggle = () => {
    if (openToggle.menuOpen) {
      handleMenuUpdate(false, true);
      setTimeout(() => {
        handleMenuUpdate(false, false);
      }, 300);
    } else {
      handleMenuUpdate(true, false);
    }

    dispatch({
      type: 'updateActive',
      makeActive: { active: -1, previous: null, parent: null },
    });
  };

  /**
   * Render.
   */
  return (
    <>
      <button
        type="button"
        className={hamburgerClasses}
        onClick={handleMenuToggle}>
        <span className="bar" />
      </button>
      <div
        className={overlayClasses}
        aria-hidden="true"
        onClick={handleMenuToggle}
      />
    </>
  );
};
export default HamburgerButton;
