/** @format */
import React from 'react';
import ReactDOM from 'react-dom';
import Header from './header.jsx';

/**
 * Build Footer.
 */
// eslint-disable-next-line import/prefer-default-export
export const buildHeader = () => {
  const header = document.getElementById('varian-header');
  if (header !== null) {
    ReactDOM.render(<Header />, header);
  }
};
