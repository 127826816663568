/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import Submenu from '../submenu.jsx';

/**
 * Basic Menu Dropdown.
 *
 * @param {array} submenuData
 */
const BasicMenuDropdown = ({ submenuData }) => {
  return (
    <div className="basic-menu-dropdown">
      <Submenu linkList={submenuData} />
    </div>
  );
};
export default BasicMenuDropdown;

/**
 * Basic Menu PropTypes.
 */
BasicMenuDropdown.propTypes = {
  submenuData: PropTypes.array,
};
