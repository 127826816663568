/**
 * Functional Roles.
 *
 * @format
 */

/* eslint-disable import/prefer-default-export */

export const functionalRoles = [
  { name: 'Chief/Dept Administrator', value: 'Chief/Dept Administrator' },
  { name: 'Dosimetrist', value: 'Dosimetrist' },
  {
    name: 'Executive Management (CXO/Owner/VP)',
    value: 'Executive Management (CXO/Owner/VP)',
  },
  { name: 'IT - Management', value: 'IT - Management' },
  { name: 'IT - Technician', value: 'IT - Technician' },
  { name: 'Nurse', value: 'Nurse' },
  { name: 'Physician', value: 'Physician' },
  { name: 'Physicist', value: 'Physicist' },
  { name: 'Therapist/Operator', value: 'Therapist/Operator' },
  { name: 'Therapist - Chief', value: 'Therapist - Chief' },
  { name: 'Other', value: 'Other' },
];
