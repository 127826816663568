/** @format */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import FeaturedMenuItem from '../../featured-menu-item/featured-menu-item.jsx';
import Submenu from '../submenu.jsx';

/**
 * Standard Menu Dropdown.
 *
 * @param {array} submenuData
 * @param {string} id
 * @param {string} description
 * @param {string} title
 * @param {object} image
 * @param {string} link
 * @param {string} linkText
 */
const StandardMenuDropdown = ({
  submenuData,
  id,
  description,
  title,
  image,
  link,
  linkText,
}) => {
  const classes = `mega-menu-dropdown tid-${id}`;

  /**
   * Render.
   */
  return (
    <div className={classes}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-9">
            <Submenu linkList={submenuData} />
          </div>
          <div className="col-lg-3 col-md-3">
            <FeaturedMenuItem
              description={description}
              title={title}
              image={image}
              link={link}
              linkText={linkText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StandardMenuDropdown;

/**
 * Mega Menu PropTypes.
 */
StandardMenuDropdown.propTypes = {
  submenuData: PropTypes.array,
  id: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  linkText: PropTypes.string,
};
