/**
 * Third Party Integrations.
 * @format
 */

/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { addUsabilla } from './usabilla';
import { loadHeapAnalytics } from './heap-analytics';
import { safeCurrentLanguage } from './drupal-services';
import { loadTrackingPixels } from './tracking-pixels';

/**
 * Script Loader.
 *
 * @param {string} scriptSrc
 * @param {string} scriptName
 * @param {Function} callback
 */
export const scriptLoader = (scriptSrc, scriptName, callback = null) => {
  const loadScript = document.createElement('script');
  loadScript.type = 'text/javascript';
  loadScript.async = true;
  loadScript.src = scriptSrc;
  loadScript.id = scriptName;
  document.body.appendChild(loadScript);

  /**
   * Callback.
   * Fire callback when script is loaded.
   */
  if (callback !== null) {
    if (loadScript.readyState) {
      loadScript.onreadystatechange = () => {
        if (
          loadScript.readyState === 'loaded' ||
          loadScript.readyState === 'complete'
        ) {
          loadScript.onreadystatechange = null;
          callback();
        }
      };
    } else {
      loadScript.onload = () => {
        callback();
      };
    }
  }
};

/**
 * Load Services.
 */
export const loadThirdPartyServices = () => {
  const currentLanguage = safeCurrentLanguage;

  /**
   * Load TrustE Cookie Preferences.
   */
  const loadTrustE = () => {
    scriptLoader(
      '//consent.truste.com/notice?domain=varian.com&c=teconsent&js=bb&noticeType=bb',
      'truste_0.3319951464230204'
    );

    scriptLoader(
      'https://consent.trustarc.com/asset/notice.js/v/v1.7-2',
      'trustarc-consent-notice'
    );
  };

  /**
   * Load DemandBase.
   */
  const loadDemandBase = () => {
    if (currentLanguage !== 'zh-hans') {
      ((d, b, a, s, e) => {
        const t = b.createElement(a);
        const fs = b.getElementsByTagName(a)[0];
        t.async = 1;
        t.id = e;
        t.src =
          (document.location.protocol === 'https:' ? 'https://' : 'http://') +
          s;
        fs.parentNode.insertBefore(t, fs);
      })(
        window,
        document,
        'script',
        'scripts.demandbase.com/jTxiSeMj.min.js',
        'demandbase_js_lib'
      );
    }
  };

  /**
   * Load LinkedIn Analytics.
   */
  const loadLinkedInAnalytics = () => {
    window._linkedin_partner_id = '942233';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    scriptLoader(
      'https://snap.licdn.com/li.lms-analytics/insight.min.js',
      'linkedin-analytics'
    );
  };

  /**
   * Load Baidu.
   */
  const loadBaidu = () => {
    if (currentLanguage === 'zh-hans') {
      scriptLoader(
        '//hm.baidu.com/hm.js?04451e76e9c2a37460a85f2b50377b73',
        'baidu'
      );
    }
  };

  /**
   * Load Usabilla.
   */
  const loadUsabilla = () => {
    if (currentLanguage !== 'ja') {
      addUsabilla();
    }
  };

  /**
   * Load Marketo.
   */
  const loadMarketo = () => {
    let didInit = false;

    /**
     * Init Munchkin.
     */
    const initMunchkin = () => {
      if (!didInit) {
        didInit = true;
        Munchkin.init('760-DZO-155');
      }
    };

    const s = document.createElement('script');
    s.id = 'Marketo';
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//munchkin.marketo.net/munchkin.js';
    s.onreadystatechange = () => {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        initMunchkin();
      }
    };
    s.onload = initMunchkin;
    document.body.appendChild(s);
  };

  /**
   * Lazy Load Services.
   */
  const lazyLoadServices = () => {
    setTimeout(() => {
      loadTrustE();
      loadDemandBase();
      loadLinkedInAnalytics();
      loadBaidu();
      loadUsabilla();
      loadHeapAnalytics();
      loadMarketo();

      // Google Tag Manager.
      scriptLoader(
        'https://www.googletagmanager.com/gtag/js?id=DC-5118829',
        'google-tag-manager'
      );

      // Marketo Forms 2.
      scriptLoader(
        '//app-sjqe.marketo.com/js/forms2/js/forms2.js',
        'marketo-form-script'
      );

      // Site Improve.
      scriptLoader(
        '//siteimproveanalytics.com/js/siteanalyze_6121624.js',
        'site-improve'
      );

      setTimeout(loadTrackingPixels(), 2000);
    }, 500);
  };
  lazyLoadServices();
};
