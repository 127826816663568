/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { safeUrl } from '../../services/drupal-services';

/**
 * Footer Link.
 *
 * @param {string} link
 * @param {string} name
 * @param {number} external
 */
const FooterLink = ({ parent, link, name, external, submenu }) => {
  const linkClasses = parent ? 'col-xs-12 col-lg-4' : '';
  const subNavigation =
    submenu !== null ? <ul className="sub-menu">{submenu}</ul> : null;
  return (
    <li className={linkClasses}>
      <a
        href={safeUrl(link)}
        target={external ? '_self' : '_blank'}
        rel={external ? '' : 'noreferrer noopener'}>
        {name}
      </a>
      {subNavigation}
    </li>
  );
};
export default FooterLink;

/**
 * Footer Link PropTypes.
 */
FooterLink.propTypes = {
  parent: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string,
  external: PropTypes.number,
  submenu: PropTypes.array,
};
