/**
 * Heap Analytics.
 * @format
 */

/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-multi-assign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable-next-line import/prefer-default-export */

const productionId = '3766665990';
const developmentId = '1732971963';

/**
 * Set Heap Environment.
 */
const setHeapEnvironment = () => {
  if (typeof drupalSettings !== 'undefined') {
    if (drupalSettings.environment === 'production') {
      return productionId;
    }
    return developmentId;
  }
  return productionId;
};

const currentId = setHeapEnvironment;

/**
 * Load Heap Analytics.
 */
export const loadHeapAnalytics = () => {
  (window.heap = window.heap || []),
    (heap.load = function(e, t) {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      let r = t.forceSSL || document.location.protocol === 'https:';
      let a = document.createElement('script');
      (a.type = 'text/javascript'),
        (a.async = !0),
        (a.src = `${
          r ? 'https:' : 'http:'
        }//cdn.heapanalytics.com/js/heap-${e}.js`);
      let n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(a, n);
      for (
        let o = function(e) {
            return function() {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
          ],
          c = 0;
        c < p.length;
        c++
      ) {
        heap[p[c]] = o(p[c]);
      }
    });
  heap.load(currentId);
};
