/** @format */

import React, { useContext } from 'react';
import { buildFooterLinks } from '../footer-link-builder';
import { NavigationContext } from '../../context/navigation-context';

/**
 * Footer Legal Links.
 */
const LegalLinks = () => {
  const navigationData = useContext(NavigationContext);
  const { legal_menu_vocab: legalLinks } = navigationData;
  const legalMenu = legalLinks ? buildFooterLinks(legalLinks) : null;
  const legalClasses = legalLinks
    ? 'footer-legal-nav'
    : 'footer-legal-nav hide';

  return (
    <div className="legal-footer-wrapper">
      <div className="container">
        <nav role="navigation">
          <ul className={legalClasses}>{legalMenu}</ul>
        </nav>
      </div>
    </div>
  );
};
export default LegalLinks;
