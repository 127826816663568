/**
 * WeChat Modal Window.
 * @format
 */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { safeTranslate } from '../../../services/drupal-services';

/**
 * Wechat.
 */
const WeChatModal = () => {
  const [windowOpen, setWindowOpen] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const transitioningClass = transitioning ? 'transitioning' : '';
  const weChatModalClass = windowOpen
    ? `generic-form wechat-modal container show ${transitioningClass}`
    : `generic-form wechat-modal container ${transitioningClass}`;
  const weChatModalOverlayClass = windowOpen
    ? 'form-overlay show'
    : 'form-overlay container';

  /**
   * Translated Blob Text.
   */
  const close = safeTranslate('Close');

  /**
   * Toggle WeChat Window.
   */
  const toggleWeChatWindow = (e) => {
    e.preventDefault();
    setWindowOpen(!windowOpen);
  };

  /**
   * Render.
   */
  return (
    <>
      <a
        href="https://www.wechat.com/en"
        target="_blank"
        rel="noopener noreferrer"
        onClick={toggleWeChatWindow}>
        <i className="fab fa-weixin" />
      </a>
      <div
        className={weChatModalOverlayClass}
        onClick={toggleWeChatWindow}
        role="button"
      />
      <div className={weChatModalClass}>
        <button
          className="close-form fa"
          onClick={toggleWeChatWindow}
          type="button">
          {close}
        </button>
        <h2>微信公众号</h2>
        <div className="row">
          <div className="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <img
              alt="瓦里安医疗 QR Code"
              src="/themes/custom/varian/build/images/QR-VarianChina.png"
              className="img-responsive"
            />
            <p>瓦里安医疗</p>
          </div>
          <div className="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <img
              alt="放疗界 QR Code"
              src="/themes/custom/varian/build/images/QR-Fangliaojie.png"
              className="img-responsive"
            />
            <p>放疗界</p>
          </div>
          <div className="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <img
              alt="瓦里安客户服务 QR Code"
              src="/themes/custom/varian/build/images/QR-CustomerSupport.png"
              className="img-responsive"
            />
            <p>瓦里安客户服务</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default WeChatModal;

/**
 * Contact Form PropTypes.
 */
WeChatModal.propTypes = {
  region: PropTypes.string,
};
