/**
 * Language Services.
 * @format
 */

import {
  safeTranslate,
  safeCurrentLanguage,
} from '../../services/drupal-services';

/**
 * Get Region Name.
 * @param {string} region
 */
export const getRegionName = (region) => {
  let regionName;

  switch (region) {
    case 'americas':
      regionName = safeTranslate('Americas');
      break;
    case 'apac':
      regionName = safeTranslate('Asia-Pacific');
      break;
    case 'emia':
      regionName = safeTranslate('Africa, Europe & Middle-East');
      break;
    default:
      regionName = '';
  }

  return regionName;
};

/**
 * Get Relative Path.
 */
export const getRelativePath = (languageList) => {
  let pathName = window.location.pathname;
  if (languageList !== null && languageList.length > 0) {
    if (languageList.length > 0) {
      languageList.forEach((language) => {
        const { locale_language_code: id } = language;
        const path = pathName.split('/');
        if (path[1] === id) {
          pathName = pathName.replace(`/${id}`, '');
        }
      });
    }
  }
  return pathName;
};

/**
 * Get Current Language.
 */
export const getCurrentLanguage = (languageList) => {
  let currentLanguage = '';
  let flag = 'no-flag';
  const currentLanguageId = safeCurrentLanguage;
  if (languageList && languageList.length > 0) {
    languageList.forEach((language) => {
      const {
        language_display_text: translatedName,
        locale_language_code: languageCode,
        locale_flag: localeFlag,
      } = language;
      if (languageCode === currentLanguageId) {
        currentLanguage = translatedName;
        flag = localeFlag;
      }
    });
  }
  return { language: currentLanguage, flag };
};
