/**
 * IE11 Polyfils.
 * @format
 */
/* eslint-disable import/prefer-default-export */

import objectFitImages from 'object-fit-images';
import 'intersection-observer';

export const loadPolyfils = () => {
  const adjustImages = () => {
    const fittedImages = document.querySelectorAll('img.img-object-fit');
    objectFitImages(fittedImages);
  };
  adjustImages();
};
