/**
 * Footer Link Builder.
 *
 * @format
 */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import FooterLink from './footer-link.jsx';
import ContactButton from '../forms/contact-form/contact-button.jsx';

/**
 * Build Footer Menu.
 *
 * @param {object} data
 * @param {boolean} parent
 */
export const buildFooterLinks = (apiData, parent = null) => {
  if (apiData.length > 0) {
    return apiData.map((data) => {
      const { name, link, new_window: external, children, tid } = data;
      const submenu = children !== null ? buildFooterLinks(children) : null;

      if (tid === '22') {
        return <ContactButton name={name} key={tid} />;
      }

      return (
        <FooterLink
          key={tid}
          parent={parent}
          link={link}
          name={name}
          external={parseInt(external, 10)}
          submenu={submenu}
        />
      );
    });
  }
  return null;
};
