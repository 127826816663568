/**
 * Mega Menu.
 * @format
 */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect, useRef, useContext } from 'react';
import SearchDropdown from '../search/search-dropdown/search-dropdown.jsx';
import { NavigationContext } from '../context/navigation-context';
import { getSubmenu } from './build-submenus';
import { safeTranslate, safeUrl } from '../../services/drupal-services';

/**
 * Primary Navigation.
 */
const MegaMenu = () => {
  const navigationWrapper = useRef(null);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const navigationData = useContext(NavigationContext);
  const { hdr_main_menu_vocab: menuData } = navigationData;
  const searchText = safeTranslate('Search');
  const searchClasses =
    activeMenuItem === -1 ? 'search-bar active' : 'search-bar';
  const dataLoaded = menuData
    ? 'primary-navigation hidden-md hidden-sm hidden-xs'
    : 'primary-navigation hidden-md hidden-sm hidden-xs hide';
  const searchIcon = safeUrl(
    '/themes/custom/varian/build/images/Search20px.png'
  );

  /**
   * Update Active.
   *
   * @param {Object} event
   * @param {number} id
   */
  const updateActive = (event, id) => {
    event.preventDefault();
    setActiveMenuItem(id);
  };

  /**
   * Handle Click.
   *
   * @param {Object} event
   */
  const handleClick = (event) => {
    if (
      navigationWrapper.current &&
      !navigationWrapper.current.contains(event.target)
    ) {
      setActiveMenuItem(null);
    }
  };

  /**
   * Use Effect.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  /**
   * Close Mega Menu.
   */
  const closeMegaMenu = () => {
    if (activeMenuItem !== null) {
      setActiveMenuItem(null);
    }
  };

  /**
   * Window Reisze Event.
   */
  useEffect(() => {
    window.addEventListener('resize', closeMegaMenu);
    return () => {
      window.removeEventListener('resize', closeMegaMenu);
    };
  }, [activeMenuItem]);

  /**
   * Build Primary Navigation.
   *
   * @param {object} menu
   */
  const buildPrimaryNavigation = (menu) => {
    if (!menu) {
      return null;
    }
    return menu.map((topLevelNavigationItem) => {
      const {
        tid,
        name,
        children: submenuData,
        featured_description: description,
        featured_title: title,
        featured_image: image,
        featured_link: link,
        featured_link_text: linkText,
      } = topLevelNavigationItem;
      const menuItemClasses = activeMenuItem === tid ? 'active' : '';
      const submenu = getSubmenu(
        tid,
        submenuData,
        description,
        title,
        image,
        link,
        linkText
      );

      return (
        <li key={tid} className={menuItemClasses}>
          <button type="button" onClick={(event) => updateActive(event, tid)}>
            {name}
          </button>
          {submenu}
        </li>
      );
    });
  };

  /**
   * Render.
   */
  return (
    <nav className={dataLoaded} ref={navigationWrapper}>
      <ul>
        {buildPrimaryNavigation(menuData)}
        <li className={searchClasses}>
          <button type="button" onClick={(event) => updateActive(event, -1)}>
            <img className="search-icon" src={searchIcon} aria-hidden="true" />
            {searchText}
          </button>
          <SearchDropdown />
        </li>
      </ul>
    </nav>
  );
};
export default MegaMenu;
