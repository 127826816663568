/**
 * Responsive Submenu.
 * @format
 */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { useStateValue } from '../../context/state-context';
import FeaturedMenuItem from '../../featured-menu-item/featured-menu-item.jsx';
import ContactButton from '../../forms/contact-form/contact-button.jsx';
import { safeUrl } from '../../../services/drupal-services';

/**
 * Parent Check.
 *
 * @param {string} tid
 */
const parentCheck = (tid) => {
  let showButton = false;
  switch (tid) {
    case '29':
    case '30':
    case '31':
    case '32':
    case '73':
    case '74':
    case '75':
    case '76':
    case '77':
    case '78':
      showButton = true;
      break;
    default:
      showButton = false;
  }
  return showButton;
};

/**
 * Responsive Submenu.
 */
const ResponsiveSubmenu = ({ menuData, parent, title, featured }) => {
  if (!menuData) {
    return null;
  }
  const [{ menuItem, swipeToggle }, dispatch] = useStateValue();

  /**
   * Navigation Transition.
   *
   * @param {*} tid
   */
  const navigationTransition = (tid, transition = false) => {
    const transitionDirection = tid === 'previous' ? 'backward' : 'forward';
    dispatch({
      type: 'navigating',
      setTransition: {
        transitioning: transition,
        direction: transitionDirection,
      },
    });

    if (transition) {
      setTimeout(() => {
        navigationTransition(tid);
      }, 400);
    }
  };

  /**
   * Update Navigation.
   *
   * @param {*} tid
   */
  const updateNavigation = (tid) => {
    if (!swipeToggle.currentlySwiping) {
      const parentItem = tid === 'previous' ? null : menuItem.previous;
      const previousItem =
        tid === 'previous' ? menuItem.parent : menuItem.active;
      const activeItem = tid === 'previous' ? menuItem.previous : tid;

      dispatch({
        type: 'updateActive',
        makeActive: {
          previous: previousItem,
          active: activeItem,
          parent: parentItem,
        },
      });
      navigationTransition(tid, true);
    }
  };

  /**
   * Set Swipe.
   * @param {bool} swiping
   */
  const setSwiping = (swiping) => {
    dispatch({
      type: 'swipingMenu',
      setSwipe: { currentlySwiping: swiping },
    });
  };

  /**
   * Swipe Handlers.
   */
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      updateNavigation('previous');
      setSwiping(true);
      setTimeout(() => {
        setSwiping(false);
      }, 300);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  /**
   * Build Submenu.
   * @param {array} links
   */
  const buildSubmenu = (links) => {
    return links.map((currentLink) => {
      const {
        tid,
        name,
        link,
        new_window: newWindow,
        children,
        featured_description: description,
        featured_title: featuredTitle,
        featured_image: image,
        featured_link: featuredLink,
        featured_link_text: linkText,
      } = currentLink;
      const isNewWindow = newWindow === '0';
      const hasSubmenu = children ? 'has-submenu' : '';
      const isActive =
        menuItem.active === tid || menuItem.previous === tid ? 'active' : '';
      const current = menuItem.active === tid ? 'current' : '';
      const boldCheck = tid === '92' ? 'bold' : '';
      const linkClasses = `menu-item-${tid} ${isActive} ${current} ${boldCheck} parent-${parent} ${hasSubmenu}`;
      const isParent = parentCheck(tid);
      const featuredItem = {
        description,
        featuredTitle,
        image,
        link: featuredLink,
        linkText,
      };
      const linkButton = isParent ? (
        <button type="button" onClick={() => updateNavigation(tid, name)}>
          {name}
        </button>
      ) : (
        <a
          href={safeUrl(link)}
          target={isNewWindow ? '_self' : '_blank'}
          rel={isNewWindow ? '' : 'noreferrer noopener'}>
          {name}
        </a>
      );

      if (tid === '93') {
        return <ContactButton name={name} key={tid} />;
      }

      return (
        <li key={tid} className={linkClasses}>
          {linkButton}
          <ResponsiveSubmenu
            menuData={children}
            parent={tid}
            title={name}
            featured={featuredItem}
          />
        </li>
      );
    });
  };

  /**
   * Render.
   */
  return parentCheck(parent) ? (
    <div className="submenu" {...swipeHandlers}>
      <button
        type="button"
        className="back-button"
        onClick={() => updateNavigation('previous')}>
        Back
      </button>
      {title !== null ? <div className="submenu-title">{title}</div> : null}
      <ul>{buildSubmenu(menuData)}</ul>
      {parent !== '29' && parent !== '30' ? (
        <FeaturedMenuItem
          description={featured.description}
          title={featured.title}
          image={featured.image}
          link={featured.link}
          linkText={featured.linkText}
        />
      ) : null}
    </div>
  ) : (
    <ul>{buildSubmenu(menuData)}</ul>
  );
};
export default ResponsiveSubmenu;

/**
 * Navigation Provider ProptTypes.
 */
ResponsiveSubmenu.propTypes = {
  menuData: PropTypes.array,
  parent: PropTypes.string,
  title: PropTypes.string,
  featured: PropTypes.object,
};
