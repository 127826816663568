/**
 * Auxiliary Navigation.
 * @format
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { NavigationContext } from '../../context/navigation-context';
import { buildAuxilaryLinks } from './auxilary-links';
import LanguageSelectorButton from '../../language-selector/language-selector-button/lanugage-selector-button.jsx';

/**
 * Auxiliary Navigation.
 */
const AuxiliaryNavigation = () => {
  const navigationData = useContext(NavigationContext);
  const { hdr_top_menu_vocab: auxLinks } = navigationData;
  const links = auxLinks ? buildAuxilaryLinks(auxLinks) : null;
  const LanguageSelector =
    typeof Drupal !== 'undefined' ? (
      <li className="language-selector-aux-link">
        <LanguageSelectorButton />
      </li>
    ) : null;

  /**
   * Render.
   */
  return (
    <nav className="auxiliary-navigation">
      <ul>
        {links}
        {LanguageSelector}
      </ul>
    </nav>
  );
};
export default AuxiliaryNavigation;
