/**
 * Navigation Context.
 *
 * @format *
 */

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { getData } from '../menu-api/get-data';
import { safeLanguageUrlPrefix } from '../../services/drupal-services';

/**
 * Navigation Context.
 */
export const NavigationContext = createContext();

/**
 * Navigation Provider.
 *
 * @param {object} props
 */
export const NavigationProvider = (props) => {
  const navigationData = getData(
    `/${safeLanguageUrlPrefix}rest-api/varian-menu/all?_format=json`
  );
  const { children } = props;
  return (
    <NavigationContext.Provider value={navigationData}>
      {children}
    </NavigationContext.Provider>
  );
};

/**
 * Navigation Provider ProptTypes.
 */
NavigationProvider.propTypes = {
  props: PropTypes.object,
  children: PropTypes.node.isRequired,
};
