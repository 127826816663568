/**
 *
 * @format
 */

/**
 * Contact Form.
 * @format
 */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form/dist/react-hook-form.ie11';
import { useStateValue } from '../../context/state-context';
import SubmissionResponse from '../submission-response/submssion-response.jsx';
import FormFields from '../fields/form-fields.jsx';
import { safeTranslate } from '../../../services/drupal-services';
import { contactFormFields } from './contact-form-fields';
import { submitForm } from '../marketo-form-services';

/**
 * Contact Form.
 * @param {string} formType
 */
const ContactForm = ({ region }) => {
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const [{ contactToggle }, dispatch] = useStateValue();
  const transitioningClass = transitioning ? 'transitioning' : '';
  const submittedClass = submissionSuccessful ? 'form-submitted' : '';
  const contactFormModalClass = contactToggle.contactFormOpen
    ? `generic-form container show ${transitioningClass} ${submittedClass}`
    : `generic-form container ${transitioningClass} ${submittedClass}`;
  const contactFormOverlayClass = contactToggle.contactFormOpen
    ? 'form-overlay show'
    : 'form-overlay container';
  const formHtmlId = `contact-form-${region}`;

  /**
   * Translated Blob Text.
   */
  const contactForm = safeTranslate('Contact Form');
  const close = safeTranslate('Close');
  const contactUs = safeTranslate('Contact Us');
  const giveUsACall = safeTranslate('Give us a call');
  const or = safeTranslate('or...');
  const emailUs = safeTranslate('Email us');
  const submit = safeTranslate('Submit');

  /**
   * Toggle Contact Form.
   */
  const toggleContactForm = () => {
    dispatch({
      type: 'toggleContactForm',
      formToggle: { contactFormOpen: !contactToggle.contactFormOpen },
    });
    reset();
    setSubmissionSuccessful(false);
  };

  /**
   * Submit Contact Form.
   *
   * @param event
   */
  const submitContactForm = () => {
    setSubmissionSuccessful(true);
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 300);
    submitForm(formHtmlId, 'contact-form', 1094);
  };

  /**
   * Render.
   */
  return (
    <>
      <div
        className={contactFormOverlayClass}
        onClick={toggleContactForm}
        role="button"
      />
      <div className={contactFormModalClass} id={formHtmlId}>
        <form
          className="generic-form-inner"
          aria-label={contactForm}
          onSubmit={handleSubmit(submitContactForm)}>
          <button
            className="close-form fa"
            onClick={toggleContactForm}
            type="button">
            {close}
          </button>
          <SubmissionResponse successfulSubmission={submissionSuccessful} />
          <div className="form-container">
            <h2>{contactUs}</h2>
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                <FormFields
                  fieldList={contactFormFields}
                  register={register}
                  errors={errors}
                />
                <button
                  value={submit}
                  type="submit"
                  className="btn-submit"
                  formNoValidate>
                  {submit}
                </button>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                <div className="contact-info">
                  <div className="or">{or}</div>
                  <div className="call-us">
                    {giveUsACall}
                    <span className="phone">1 (800) 544-4636</span>
                  </div>
                  <div className="email-us">
                    {emailUs}
                    <span className="email">support@varian.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default ContactForm;

/**
 * Contact Form PropTypes.
 */
ContactForm.propTypes = {
  region: PropTypes.string,
};
