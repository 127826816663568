/** @format */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FeaturedMenuItem from '../../featured-menu-item/featured-menu-item.jsx';
import Submenu from '../submenu.jsx';

/**
 * Product Menu Dropdown.
 *
 * @param {array} submenuData
 */
const ProductMenuDropdown = ({ submenuData }) => {
  const productMenuWrapper = useRef(null);
  const [activeProductMenu, setActiveProductMenu] = useState('73');
  const [previousProductMenu, setPreviousProductMenu] = useState(null);
  const [transitioning, setTransitioning] = useState(false);
  const currentProductMenu = `product-menu tid-${activeProductMenu}`;

  /**
   * Handle Click.
   *
   * @param {Object} event
   */
  const handleClick = (event) => {
    if (
      productMenuWrapper.current &&
      !productMenuWrapper.current.contains(event.target)
    ) {
      setTimeout(() => {
        setActiveProductMenu('73');
      }, 200);
    }
  };

  /**
   * Use Effect.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  /**
   * Update Active.
   *
   * @param {Object} event
   * @param {number} id
   */
  const updateActiveProductMenu = (event, id) => {
    event.preventDefault();
    if (id !== activeProductMenu && !transitioning) {
      setPreviousProductMenu(activeProductMenu);
      setActiveProductMenu(id);
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
      }, 400);
    }
  };

  /**
   * Get Transition Classes.
   *
   * @param {number} tid
   */
  const getTransitionClasses = (tid) => {
    const active = activeProductMenu === tid ? 'active' : '';
    const closing =
      previousProductMenu === tid && transitioning ? 'closing' : '';
    const transition =
      (transitioning && activeProductMenu === tid) ||
      (transitioning && previousProductMenu === tid)
        ? 'transitioning'
        : '';
    let direction = '';
    if (
      (activeProductMenu === tid || previousProductMenu === tid) &&
      previousProductMenu !== null &&
      transitioning
    ) {
      direction =
        previousProductMenu > activeProductMenu ? 'backward' : 'forward';
    }
    return `${active} ${closing} ${transition} ${direction}`;
  };

  /**
   * Product Navigation.
   */
  const buildProductNavigation = (productSubmenu) => {
    if (!productSubmenu) {
      return null;
    }
    return productSubmenu.map((productCategory) => {
      const {
        tid,
        name,
        children: submenu,
        featured_description: description,
        featured_title: title,
        featured_image: image,
        featured_link: link,
        featured_link_text: linkText,
      } = productCategory;
      const menuItemClasses = getTransitionClasses(tid);
      const submenuClasses = `product-submenu row submenu-${tid}`;

      return (
        <li key={tid} className={menuItemClasses}>
          <button
            type="button"
            onClick={(event) => updateActiveProductMenu(event, tid)}>
            <span>{name}</span>
          </button>
          <div className={submenuClasses}>
            <div className="col-lg-8">
              <Submenu linkList={submenu} />
            </div>
            <div className="col-lg-4">
              <FeaturedMenuItem
                description={description}
                title={title}
                image={image}
                link={link}
                linkText={linkText}
              />
            </div>
          </div>
        </li>
      );
    });
  };

  /**
   * Render.
   */
  return (
    <div className="product-menu-dropdown" ref={productMenuWrapper}>
      <div className="product-menu-wrapper">
        <div className="container">
          <div className={currentProductMenu}>
            <ul>{buildProductNavigation(submenuData)}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductMenuDropdown;

/**
 * Mega Menu PropTypes.
 */
ProductMenuDropdown.propTypes = {
  submenuData: PropTypes.array,
};
