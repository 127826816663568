/** @format */

import React from 'react';
import AuxiliaryNavigation from './auxiliary-navigation/auxiliary-navigation.jsx';
import LanguageSelector from '../language-selector/language-selector.jsx';
import { NavigationProvider } from '../context/navigation-context';
import { StateProvider } from '../context/state-context';
import NavigationContainer from './navigation-container/navigation-container.jsx';
import ContactForm from '../forms/contact-form/contact-form.jsx';
import ExternalSpacer from './external-spacer.jsx';

/**
 * Header.
 */
const Header = () => {
  /**
   * Render.
   */
  return (
    <NavigationProvider>
      <StateProvider>
        <ExternalSpacer />
        <div className="top-header-bar hidden-md hidden-sm hidden-xs">
          <div className="container">
            <AuxiliaryNavigation />
          </div>
        </div>
        <NavigationContainer />
        <LanguageSelector />
        <ContactForm region="header" />
      </StateProvider>
    </NavigationProvider>
  );
};
export default Header;
