/**
 * Contact Categories.
 *
 * @format
 */
/* eslint-disable import/prefer-default-export */

export const contactCategoriesList = [
  {
    name: 'Clinical & Technical Product Support',
    value: 'Clinical & Technical Product Support',
  },
  { name: 'Human Resources', value: 'Human Resources' },
  { name: 'Investor Relations', value: 'Investor Relations' },
  { name: 'Media Relations', value: 'Media Relations' },
  { name: 'Medical Affairs', value: 'Medical Affairs' },
  { name: 'Training & Education', value: 'Training & Education' },
  { name: 'Varian Unite', value: 'Varian Unite' },
];
