/** @format */

import React, { useEffect, useState } from 'react';
import { of, fromEvent, animationFrameScheduler } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';
import { useStateValue } from '../../context/state-context';
import {
  safeUrl,
  safeLanguageUrlPrefix,
} from '../../../services/drupal-services';
import MegaMenu from '../../mega-menu/mega-menu.jsx';
import ResponsiveMenu from '../../responsive-menu/responsive-menu.jsx';

/**
 * Navigation Container.
 */
const NavigationContainer = () => {
  /**
   * Watch Scroll.
   */
  const watchScroll = () =>
    of(typeof window === 'undefined').pipe(
      filter((bool) => !bool),
      switchMap(() => fromEvent(window, 'scroll', { passive: true })),
      throttleTime(0, animationFrameScheduler),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]) => (y2 < y1 ? 'up' : 'down')),
      distinctUntilChanged()
    );

  const scrollDirection = useObservable(watchScroll, 'Up');
  const [{ openToggle }] = useStateValue();
  const closingClass = openToggle.closing ? 'closing' : '';
  const [atTop, setAtTop] = useState(true);
  const showMenuClass =
    scrollDirection === 'up' && !openToggle.menuOpen ? 'show' : '';
  const atTopClass = atTop ? 'top' : '';
  const headerBarClasses = openToggle.menuOpen
    ? `main-header-bar menu-open ${atTopClass} ${showMenuClass} ${closingClass}`
    : `main-header-bar ${atTopClass} ${showMenuClass} ${closingClass}`;
  const logoUrl = safeUrl(
    '/themes/custom/varian/build/images/varian-logo-black.png'
  );

  /**
   * Offset Check.
   */
  useEffect(() => {
    const header = document.getElementById('main-header-bar');
    const offset = header.offsetTop + 80;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset > offset) {
        setAtTop(false);
      } else {
        setAtTop(true);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  /**
   * Render.
   */
  return (
    <div className={headerBarClasses} id="main-header-bar">
      <div className="container navigation-container">
        <a href={`/${safeLanguageUrlPrefix}`} className="logo">
          <img src={logoUrl} title="Varian" alt="Varian" />
        </a>
        <MegaMenu />
        <ResponsiveMenu />
      </div>
    </div>
  );
};
export default NavigationContainer;
