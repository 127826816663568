/**
 * Search Dropdown.
 * @format
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDebounce } from '../../custom-hooks/use-debounce';
import {
  safeTranslate,
  safeUrl,
  safeCurrentLanguage,
} from '../../../services/drupal-services';
import SearchResults from './search-results.jsx';

const SearchDropdown = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchDropdownWrapper = useRef(null);
  const searchIcon = safeUrl(
    '/themes/custom/varian/build/images/Search20px.png'
  );
  const probeamSearchLink = safeUrl('/search?search=Probeam');
  const thirdPartySearchLink = safeUrl(
    '/search?search=Third+party+integrations'
  );
  const bravosSearchLink = safeUrl('/search?search=Bravos');
  const realTimeSearchLink = safeUrl('/search?search=Real-time+management');
  const currentLanguage =
    safeCurrentLanguage === 'en' ? '' : `${safeCurrentLanguage}/`;

  /**
   * Translated Strings.
   */
  const mostSearchedTerms = safeTranslate('Popular searched terms');
  const search = safeTranslate('Search');
  const placeholderText = safeTranslate(
    'Start typing and press enter to see results'
  );
  const ariaSearch = safeTranslate('Search through site content');
  const probeamText = safeTranslate('Probeam');
  const thirdPartyText = safeTranslate('Third party integrations');
  const bravosText = safeTranslate('Bravos');
  const realTimeText = safeTranslate('Real-time management');

  /**
   * Handle Key Press
   *
   * @param {string} key
   */
  const handleKeyPress = (key) => {
    if (key === 'Enter') {
      const redirectUrl = safeUrl(
        `/${currentLanguage}search?search=${searchTerm}`
      );
      window.location = redirectUrl;
    }
  };

  /**
   * Search Results.
   */
  const searchingMessage = isSearching ? (
    <div className="search-results">Searching for {searchTerm}...</div>
  ) : (
    ''
  );

  /**
   * Search Results.
   */
  const searchResults =
    results !== null && results !== '' && !isSearching ? (
      <SearchResults resultsData={results} searchTerm={searchTerm} />
    ) : (
      ''
    );

  /**
   * Loading Spinner.
   */
  const loading = isSearching ? (
    <i className="fas fa-spinner loading" />
  ) : (
    <i className="fas fa-spinner" />
  );

  /**
   * Handle Click.
   *
   * @param {Object} event
   */
  const handleClick = (event) => {
    if (
      searchDropdownWrapper.current &&
      !searchDropdownWrapper.current.contains(event.target)
    ) {
      setTimeout(() => {
        setIsSearching(false);
        setResults(null);
        setSearchTerm('');
      }, 200);
    }
  };

  /**
   * Search Use Effect.
   */
  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      const queryString = safeUrl(`/search.json?search=${debouncedSearchTerm}`);

      axios
        .get(queryString)
        .then((result) => {
          setResults(result.data);
          setIsSearching(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsSearching(false);
      setResults(null);
    }

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [debouncedSearchTerm]);

  /**
   * Render.
   */
  return (
    <div className="search-dropdown" ref={searchDropdownWrapper}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 search-container">
            <h2>{search}</h2>
            <div className="search-box">
              <input
                type="search"
                name="desktop-search"
                placeholder={placeholderText}
                aria-label={ariaSearch}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e.key)}
              />
              <img
                className="search-icon"
                src={searchIcon}
                aria-hidden="true"
              />
              {loading}
            </div>
            {searchingMessage}
            {searchResults}
          </div>
          <div className="col-lg-3 popular-search-terms">
            <h3>{mostSearchedTerms}</h3>
            <ul>
              <li>
                <a className="featured-link" href={probeamSearchLink}>
                  {probeamText}
                </a>
              </li>
              <li>
                <a className="featured-link" href={thirdPartySearchLink}>
                  {thirdPartyText}
                </a>
              </li>
              <li>
                <a className="featured-link" href={bravosSearchLink}>
                  {bravosText}
                </a>
              </li>
              <li>
                <a className="featured-link" href={realTimeSearchLink}>
                  {realTimeText}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchDropdown;
