/**
 * Specialty List.
 *
 * @format
 */
/* eslint-disable import/prefer-default-export */

export const specialtyList = {
  Physician: [
    { name: 'General Surgery', value: 'General Surgery' },
    { name: 'Information Technology', value: 'Information Technology' },
    { name: 'Internal Medicine', value: 'Internal Medicine' },
    { name: 'Medical Oncology', value: 'Medical Oncology' },
    { name: 'NA', value: 'NA' },
    { name: 'Neurology', value: 'Neurology' },
    { name: 'Neurosurgery', value: 'Neurosurgery' },
    { name: 'Pulmonology', value: 'Pulmonology' },
    { name: 'Radiation Oncology', value: 'Radiation Oncology' },
    { name: 'Radiology', value: 'Radiology' },
    { name: 'Thoracic Surgery', value: 'Thoracic Surgery' },
    { name: 'Urology', value: 'Urology' },
  ],
};
