/** @format */
/* eslint-disable import/prefer-default-export */

import { useState, useEffect } from 'react';

/**
 * Use Debounce.
 * @param {*} value
 * @param {*} delay
 */
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
