/**
 * Language Selector.
 * @format
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import LanguageRegion from './language-region/language-region.jsx';
import { useStateValue } from '../context/state-context';
import { safeTranslate } from '../../services/drupal-services';

/**
 * Language Selector.
 */
const LanguageSelector = () => {
  const [{ updateToggle }, dispatch] = useStateValue();
  const localeModalClass = updateToggle.localeOpen
    ? 'locale-languages container show'
    : 'locale-languages container';
  const localeOverlayClass = updateToggle.localeOpen
    ? 'locale-overlay show'
    : 'locale-overlay container';
  const selectRegion = safeTranslate('Select Your Region / Language');
  const close = safeTranslate('Close');

  /**
   * Toggle Language Selector.
   */
  const toggleLanguageSelector = () => {
    dispatch({
      type: 'toggleLocale',
      updateLocale: {
        localeOpen: !updateToggle.localeOpen,
      },
    });
  };

  /**
   * Render.
   */
  return (
    <>
      <div
        className={localeOverlayClass}
        onClick={() => toggleLanguageSelector()}
        role="button"
      />
      <div className={localeModalClass}>
        <div className="locale-inner">
          <button
            className="close-language-selector"
            onClick={() => toggleLanguageSelector()}
            type="button">
            {close}
          </button>
          <h2>{selectRegion}</h2>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <LanguageRegion region="americas" />
              <LanguageRegion region="apac" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <LanguageRegion region="emia" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LanguageSelector;
