/** @format */

import React, { useContext } from 'react';
import { buildFooterLinks } from '../footer-link-builder';
import { NavigationContext } from '../../context/navigation-context';

/**
 * Footer Contact Info.
 */
const ContactInfo = () => {
  const navigationData = useContext(NavigationContext);
  const { company_info_menu_vocab: contactLinks } = navigationData;
  const contactInfo = contactLinks ? buildFooterLinks(contactLinks) : null;
  const contactClasses = contactLinks
    ? 'contact-info-nav'
    : 'contact-info-nav hide';

  return (
    <nav role="navigation" className="col-xs-12 col-sm-6 col-lg-12">
      <ul className={contactClasses}>{contactInfo}</ul>
    </nav>
  );
};
export default ContactInfo;
