/**
 * Responsive Menu.
 * @format
 */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { NavigationContext } from '../context/navigation-context';
import { useStateValue } from '../context/state-context';
import ResponsiveSubmenu from './submenu/responsive-submenu.jsx';
import MobileSearch from '../search/mobile-search/mobile-search.jsx';
import HamburgerButton from './hamburger-button/hamburger-button.jsx';
import LanguageSelectorButton from '../language-selector/language-selector-button/lanugage-selector-button.jsx';
import { buildAuxilaryLinks } from '../header/auxiliary-navigation/auxilary-links';

/**
 * Responsive Menu.
 */
const ResponsiveMenu = () => {
  const navigationData = useContext(NavigationContext);
  const {
    hdr_main_menu_vocab: menuData,
    hdr_top_menu_vocab: auxLinks,
  } = navigationData;
  const [{ menuItem, menuTransitioning, openToggle }] = useStateValue();
  const auxNavigation = auxLinks ? buildAuxilaryLinks(auxLinks) : null;
  const LanguageSelector =
    typeof Drupal !== 'undefined' ? <LanguageSelectorButton /> : null;

  /**
   * Classes.
   */
  const activeMainMenu = menuItem.active === -1 ? 'main-active' : '';
  const { direction, transitioning } = menuTransitioning;
  const transitionDirection = transitioning ? `transitioning-${direction}` : '';
  const openClass = openToggle.menuOpen ? 'open' : '';
  const closingClass = openToggle.closing ? 'closing' : '';
  const dataLoaded = menuData
    ? `responsive-navigation hidden-lg ${transitionDirection} ${activeMainMenu}`
    : 'responsive-navigation hidden-lg hide';
  const wrapperClasses = `responsive-navigation-wrapper ${openClass} ${closingClass}`;

  /**
   * Render.
   */
  return (
    <nav className={dataLoaded}>
      {LanguageSelector}
      <HamburgerButton />
      <div className={wrapperClasses}>
        <div className="responsive-navigation-outter-container">
          <div className="responsive-navigation-container">
            <MobileSearch />
            <ResponsiveSubmenu
              menuData={menuData}
              parent="-1"
              title={null}
              featured={null}
            />
            {auxLinks ? (
              <div className="auxillary-navigation">
                <ul>{auxNavigation}</ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default ResponsiveMenu;
