/**
 * Build Submenus.
 * @format
 */ /* eslint-disable import/prefer-default-export */
import React from 'react';
import BasicMenuDropdown from './basic-menu-dropdown/basic-menu-dropdown.jsx';
import StandardMenuDropdown from './standard-menu-dropdown/standard-menu-dropdown.jsx';
import ProductMenuDropdown from './product-menu-dropdown/product-menu-dropdown.jsx';

/**
 * Get Submenu.
 * @param {*} tid
 * @param {*} submenuData
 * @param {*} description
 * @param {*} title
 * @param {*} image
 * @param {*} link
 * @param {*} linkText
 */
export const getSubmenu = (
  tid,
  submenuData,
  description,
  title,
  image,
  link,
  linkText
) => {
  let submenu = '';
  switch (tid) {
    case '29':
      submenu = <BasicMenuDropdown submenuData={submenuData} />;
      break;
    case '30':
      submenu = <ProductMenuDropdown submenuData={submenuData} />;
      break;
    case '31':
    case '32':
      submenu = (
        <StandardMenuDropdown
          submenuData={submenuData}
          id={tid}
          description={description}
          title={title}
          image={image}
          link={link}
          linkText={linkText}
        />
      );
      break;
    default:
      submenu = '';
  }
  return submenu;
};
