/**
 * Country List Dropdown.
 * @format
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { safeTranslate } from '../../../services/drupal-services';
import { contactFormFields } from '../contact-form/contact-form-fields';

/**
 * List Dropdown.
 */
const DependantSelectField = ({
  fieldProperties,
  register,
  errors,
  selector,
}) => {
  const {
    fieldTitle,
    fieldName,
    placeholderText,
    id,
    list,
    useNameAsValue,
    errorMessage,
    required,
  } = fieldProperties;
  const listName = safeTranslate(fieldTitle);
  const placeholderListText = safeTranslate(placeholderText);
  const [selected, setSelected] = useState('none');
  const dropdownClasses = selected === 'none' ? 'default-selected' : '';
  const error = errors[fieldName] && (
    <p className="error-message">
      <i className="fas fa-exclamation-circle" />
      {safeTranslate(errorMessage)}
    </p>
  );
  const ref = required ? register({ required: true }) : null;

  /**
   * List Builder.
   *
   *  @param {array} list
   * @param {bool} useName
   */
  const listBuilder = (selectList, useName = false) => {
    if (selectList && selectList.length > 0) {
      return selectList.map((listItem) => {
        const { name, value } = listItem;
        const translatedName = safeTranslate(name);
        const itemValue = useName ? name : value;
        return (
          <option key={name} value={itemValue}>
            {translatedName}
          </option>
        );
      });
    }
    return null;
  };
  const selectOptions = listBuilder(list[selector], useNameAsValue);

  /**
   * Render.
   */
  return (
    <>
      <label htmlFor={id}>{listName}</label>
      <select
        id={id}
        name={fieldName}
        className={dropdownClasses}
        ref={ref}
        onChange={(event) => setSelected(event.target.value)}>
        <option value="" defaultValue>
          {placeholderListText}
        </option>
        {selectOptions}
      </select>
      {error}
    </>
  );
};
export default DependantSelectField;

/**
 * List Dropdown PropTypes.
 */
DependantSelectField.propTypes = {
  fieldProperties: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
};
