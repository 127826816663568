/** @format */

import React from 'react';
import FooterNavigation from './navigation/footer-navigation.jsx';
import LegalLinks from './legal-links/footer-legal-links.jsx';
import ContactInfo from './contact/footer-contact-info.jsx';
import SocialFollow from './social/footer-social-follow.jsx';
import Copyright from './copyright/footer-copyright.jsx';
import { NavigationProvider } from '../context/navigation-context';
import { StateProvider } from '../context/state-context';
import ContactForm from '../forms/contact-form/contact-form.jsx';

/**
 * Footer.
 */
const Footer = () => {
  /**
   * Render.
   */
  return (
    <NavigationProvider>
      <StateProvider>
        <div className="footer-wrapper container">
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-9">
              <FooterNavigation />
            </div>
            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-3">
              <ContactInfo />
              <div className="col-xs-12 col-sm-6 col-lg-12">
                <SocialFollow />
              </div>
            </div>
          </div>
        </div>
        <LegalLinks />
        <Copyright />
        <ContactForm region="footer" />
      </StateProvider>
    </NavigationProvider>
  );
};
export default Footer;
