/**
 * Form Fields.
 * @format
 */

import React from 'react';
import PropTypes from 'prop-types';
import ListDropdown from './list-dropdown.jsx';
import FormTextInput from './text-input.jsx';

/**
 * Form Fields.
 *
 * @param {array} fieldList
 */
const FormFields = ({ fieldList, register, errors }) => {
  /**
   * Build Fields
   * @param {array} fieldData
   */
  const buildFields = (fieldData) => {
    if (fieldData && fieldData.length > 0) {
      return fieldData.map((field) => {
        const { type, id, dependantField } = field;
        if (!dependantField) {
          if (type === 'email' || type === 'textarea' || type === 'text') {
            return (
              <FormTextInput
                fieldProperties={field}
                key={id}
                register={register}
                errors={errors}
              />
            );
          }
          if (type === 'select') {
            return (
              <ListDropdown
                fieldProperties={field}
                key={id}
                register={register}
                errors={errors}
              />
            );
          }
        }
        return null;
      });
    }
    return null;
  };
  const formFields = buildFields(fieldList);

  return <>{formFields}</>;
};
export default FormFields;

/**
 * Form Fields PropTypes.
 */
FormFields.propTypes = {
  fieldList: PropTypes.array,
  register: PropTypes.func,
  errors: PropTypes.object,
};
