/**
 * State List.
 *
 * @format
 */
/* eslint-disable import/prefer-default-export */

export const stateList = {
  Australia: [
    { name: 'NSW', value: 'New South Wales' },
    { name: 'VIC', value: 'Victoria' },
    { name: 'QLD', value: 'Queensland' },
    { name: 'TAS', value: 'Tasmania' },
    { name: 'SA', value: 'South Australia' },
    { name: 'WA', value: 'Western Australia' },
    { name: 'NT', value: 'Northern Territory' },
    { name: 'ACT', value: 'Australian Capital Territory' },
  ],
  Canada: [
    { name: 'AB', value: 'Alberta' },
    { name: 'BC', value: 'British Columbia' },
    { name: 'MB', value: 'Manitoba' },
    { name: 'NB', value: 'New Brunswick' },
    { name: 'NL', value: 'Newfoundland and Labrador' },
    { name: 'NT', value: 'Northwest Territories' },
    { name: 'NS', value: 'Nova Scotia' },
    { name: 'NU', value: 'Nunavut' },
    { name: 'ON', value: 'Ontario' },
    { name: 'PE', value: 'Prince Edward Island' },
    { name: 'QC', value: 'Quebec' },
    { name: 'SK', value: 'Saskatchewan' },
    { name: 'YT', value: 'Yukon Territory' },
  ],
  'United States': [
    { name: 'AK', value: 'Alaska' },
    { name: 'AL', value: 'Alabama' },
    { name: 'AR', value: 'Arkansas' },
    { name: 'AS', value: 'American Samoa' },
    { name: 'AZ', value: 'Arizona' },
    { name: 'CA', value: 'California' },
    { name: 'CO', value: 'Colorado' },
    { name: 'CT', value: 'Connecticut' },
    { name: 'DC', value: 'District of Columbia' },
    { name: 'DE', value: 'Delaware' },
    { name: 'FL', value: 'Florida' },
    { name: 'GA', value: 'Georgia' },
    { name: 'GU', value: 'Guam' },
    { name: 'HI', value: 'Hawaii' },
    { name: 'IA', value: 'Iowa' },
    { name: 'ID', value: 'Idaho' },
    { name: 'IL', value: 'Illinois' },
    { name: 'IN', value: 'Indiana' },
    { name: 'KS', value: 'Kansas' },
    { name: 'KY', value: 'Kentucky' },
    { name: 'LA', value: 'Louisiana' },
    { name: 'MA', value: 'Massachusetts' },
    { name: 'MD', value: 'Maryland' },
    { name: 'ME', value: 'Maine' },
    { name: 'MI', value: 'Michigan' },
    { name: 'MN', value: 'Minnesota' },
    { name: 'MO', value: 'Missouri' },
    { name: 'MS', value: 'Mississippi' },
    { name: 'MT', value: 'Montana' },
    { name: 'NC', value: 'North Carolina' },
    { name: 'ND', value: 'North Dakota' },
    { name: 'NE', value: 'Nebraska' },
    { name: 'NH', value: 'New Hampshire' },
    { name: 'NJ', value: 'New Jersey' },
    { name: 'NM', value: 'New Mexico' },
    { name: 'NV', value: 'Nevada' },
    { name: 'NY', value: 'New York' },
    { name: 'OH', value: 'Ohio' },
    { name: 'OK', value: 'Oklahoma' },
    { name: 'OR', value: 'Oregon' },
    { name: 'PA', value: 'Pennsylvania' },
    { name: 'PR', value: 'Puerto Rico' },
    { name: 'RI', value: 'Rhode Island' },
    { name: 'SC', value: 'South Carolina' },
    { name: 'SD', value: 'South Dakota' },
    { name: 'TN', value: 'Tennessee' },
    { name: 'TX', value: 'Texas' },
    { name: 'UT', value: 'Utah' },
    { name: 'VA', value: 'Virginia' },
    { name: 'VI', value: 'Virgin Islands' },
    { name: 'VT', value: 'Vermont' },
    { name: 'WA', value: 'Washington' },
    { name: 'WI', value: 'Wisconsin' },
    { name: 'WV', value: 'West Virginia' },
    { name: 'WY', value: 'Wyoming' },
  ],
};
