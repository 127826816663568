/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import { safeUrl } from '../../../services/drupal-services';

/**
 * Auxilary Link.
 *
 *  @param {string} name
 * @param {string} link
 * @param {boolean} external
 */
const AuxilaryLink = ({ name, link, external }) => {
  return (
    <li>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer noopener' : ''}
        href={safeUrl(link)}>
        {name}
      </a>
    </li>
  );
};
export default AuxilaryLink;

/**
 * Auxilary Link PropTypes.
 */
AuxilaryLink.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  external: PropTypes.bool,
};
