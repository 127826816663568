/**
 * Get Data.
 * Funcations for getting and structuring menu api data.
 *
 * @format
 */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */

import { useEffect, useState } from 'react';
import axios from 'axios';
import { safeUrl } from '../../services/drupal-services';

/**
 * Get Data.
 *
 * @param {string} apiEndpoint
 * @return {Object}
 */
export const getData = (apiEndpoint) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(safeUrl(apiEndpoint))
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return data;
};
