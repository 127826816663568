/** @format */

import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

/**
 * State Context.
 */
export const StateContext = createContext();

/**
 * State Provider.
 *
 * @param {*} param0
 */
export const StateProvider = ({ children }) => {
  const initialState = {
    menuItem: { active: null, previous: null, parent: null },
    menuTransitioning: { transitioning: false, direction: null },
    openToggle: { menuOpen: false, closing: false },
    updateToggle: { localeOpen: false },
    swipeToggle: { currentlySwiping: false },
    contactToggle: { contactFormOpen: false },
  };

  /**
   * Context Reducer.
   *
   * @param {*} state
   * @param {*} action
   */
  const reducer = (state, action) => {
    switch (action.type) {
      case 'updateActive':
        return {
          ...state,
          menuItem: action.makeActive,
        };

      case 'navigating':
        return {
          ...state,
          menuTransitioning: action.setTransition,
        };

      case 'toggleMenu':
        return {
          ...state,
          openToggle: action.toggleOpen,
        };

      case 'toggleLocale':
        return {
          ...state,
          updateToggle: action.updateLocale,
        };

      case 'swipingMenu':
        return {
          ...state,
          swipeToggle: action.setSwipe,
        };

      case 'toggleContactForm':
        return {
          ...state,
          contactToggle: action.formToggle,
        };

      default:
        return state;
    }
  };

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

/**
 * Use State Value.
 */
export const useStateValue = () => useContext(StateContext);

/**
 * State Provider ProptTypes.
 */
StateProvider.propTypes = {
  reducer: PropTypes.func,
  initialState: PropTypes.object,
  children: PropTypes.array,
};
