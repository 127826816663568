/**
 * External Spacer.
 * @format
 */

import React from 'react';
import { useStateValue } from '../context/state-context';

/**
 * External Spacer.
 */
const ExternalSpacer = () => {
  const [{ openToggle }] = useStateValue();
  const spacerClasses = openToggle.menuOpen
    ? 'external-header-spacer show'
    : 'external-header-spacer';

  return typeof Drupal !== 'undefined' ? null : (
    <div className={spacerClasses} />
  );
};
export default ExternalSpacer;
