/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import {
  safeTranslate,
  safeCurrentLanguage,
  safeUrl,
} from '../../../services/drupal-services';

/**
 * Highlight Text.
 *
 * @param {string} text
 * @param {string} searchTerm
 */
const highlightText = (text, searchTerm) => {
  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return parts.map((part, i) => (
    <span
      key={i}
      className={
        part.toLowerCase() === searchTerm.toLowerCase() ? 'highlight' : ''
      }>
      {part}
    </span>
  ));
};

/**
 * Search Results.
 *
 * @param {array} resultsData
 */
const SearchResults = ({ resultsData, searchTerm }) => {
  let results = (
    <li className="no-results">
      {safeTranslate('Your search did not yield any results.')}
    </li>
  );
  const showingResults = safeTranslate('Showing search results for ');
  const viewMore = safeTranslate('View more search results');
  const currentLanguage =
    safeCurrentLanguage === 'en' ? '' : `${safeCurrentLanguage}/`;
  const fullResults = safeUrl(`/${currentLanguage}search?search=${searchTerm}`);
  const moreResults =
    resultsData.length === 5 ? (
      <div className="view-more-results">
        <a className="featured-link" href={fullResults}>
          {viewMore}
        </a>
      </div>
    ) : null;

  if (resultsData && resultsData.length > 0) {
    let count = 0;
    results = resultsData.map((searchResult) => {
      const { type, title, description, link } = searchResult;
      const safeType = type.replace(' ', '-').toLowerCase();
      const safeLink =
        link && link.length > 0 ? safeUrl(link.split('"')[1]) : '#';

      return (
        <li key={count++} className={safeType}>
          <a href={safeLink} hrefLang={safeCurrentLanguage}>
            {highlightText(title, searchTerm)}
          </a>
          <p>{highlightText(description, searchTerm)}</p>
        </li>
      );
    });
  }
  return (
    <div className="search-results">
      <h2>
        {showingResults} &ldquo;{searchTerm}&ldquo;
      </h2>
      <ul>{results}</ul>
      {moreResults}
    </div>
  );
};
export default SearchResults;

/**
 * Search Results PropTypes.
 */
SearchResults.propTypes = {
  resultsData: PropTypes.array,
  searchTerm: PropTypes.string,
};
