/**
 * Contact Button.
 * @format
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStateValue } from '../../context/state-context';

/**
 * Contact Button.
 * @param {string} name
 */
const ContactButton = ({ name }) => {
  const [{ contactToggle }, dispatch] = useStateValue();

  /**
   * Toggle Contact Form.
   */
  const toggleContactForm = () => {
    dispatch({
      type: 'toggleContactForm',
      formToggle: { contactFormOpen: !contactToggle.contactFormOpen },
    });
  };

  /**
   * Render.
   */

  return (
    <li className="contact-form-button">
      <button type="button" onClick={toggleContactForm}>
        {name}
      </button>
    </li>
  );
};
export default ContactButton;

/**
 * Contact Button PropTypes.
 */
ContactButton.propTypes = {
  name: PropTypes.string,
};
