/**
 * Country List Dropdown.
 * @format
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { safeTranslate } from '../../../services/drupal-services';
import { contactFormFields } from '../contact-form/contact-form-fields';
import DependantSelectField from './dependant-list-dropdown.jsx';

/**
 * List Dropdown.
 */
const ListDropdown = ({ fieldProperties, register, errors }) => {
  const {
    fieldTitle,
    fieldName,
    placeholderText,
    id,
    list,
    useNameAsValue,
    errorMessage,
    required,
    valueCheck,
    dependantFieldID,
  } = fieldProperties;
  const listName = safeTranslate(fieldTitle);
  const placeholderListText = safeTranslate(placeholderText);
  const [selected, setSelected] = useState('none');
  const dropdownClasses = selected === 'none' ? 'default-selected' : '';
  const error = errors[fieldName] && (
    <p className="error-message">
      <i className="fas fa-exclamation-circle" />
      {safeTranslate(errorMessage)}
    </p>
  );
  const ref = required ? register({ required: true }) : null;
  const [dependency, setDependency] = useState('');

  /**
   * List Builder.
   *
   *  @param {array} list
   * @param {bool} useName
   */
  const listBuilder = (selectList, useName = false) => {
    if (selectList && selectList.length > 0) {
      return selectList.map((listItem) => {
        const { name, value } = listItem;
        const translatedName = safeTranslate(name);
        const itemValue = useName ? name : value;
        return (
          <option key={name} value={itemValue}>
            {translatedName}
          </option>
        );
      });
    }
    return null;
  };
  const selectOptions = listBuilder(list, useNameAsValue);

  /**
   * List Dependency Check.
   */
  // valueCheck ['US', 'Canada', 'Australia']
  // lists ['us-states', 'canda-provinces', 'australian-provinces']
  // const listDependencyCheck = () => {
  //   // switch value:
  //   //   case 'US':
  //   //     listBuilder(lists[0]);
  // }
  const dep = (e) => {
    setSelected(e);
    if (valueCheck && e !== 'none') {
      if (valueCheck.filter((elm) => elm.value === e).length > 0) {
        setDependency(e);
        const depField = contactFormFields.filter(
          (i) => i.id === dependantFieldID
        );

        setDependency(
          <DependantSelectField
            fieldProperties={depField[0]}
            selector={e}
            key={id}
            register={register}
            errors={errors}
          />
        );
      } else {
        setDependency('');
      }
    }
    return 'none';
  };

  /**
   * Render.
   */
  return (
    <>
      <label htmlFor={id}>{listName}</label>
      <select
        id={id}
        name={fieldName}
        className={dropdownClasses}
        ref={ref}
        data-dependency={dependency}
        onChange={(event) => dep(event.target.value)}>
        <option value="" defaultValue>
          {placeholderListText}
        </option>
        {selectOptions}
      </select>
      {error}
      {dependency}
    </>
  );
};
export default ListDropdown;

/**
 * List Dropdown PropTypes.
 */
ListDropdown.propTypes = {
  fieldProperties: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
};
