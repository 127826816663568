/** @format */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LanguageLink from '../language-links/language-link.jsx';
import { NavigationContext } from '../../context/navigation-context';
import { getRelativePath, getRegionName } from '../language-services';

/**
 * Language Region.
 *
 * @param {object} languageList
 */
const LanguageRegion = ({ region }) => {
  const navigationData = useContext(NavigationContext);
  const { locale_languages: localeLanguages } = navigationData;
  let linksFound = false;
  const regionName = getRegionName(region);

  /**
   * Get Region Links.
   */
  const getRegionLinks = (languageList) => {
    let links = [];
    if (languageList && languageList.length > 0) {
      const path = getRelativePath(languageList);
      links = languageList.map((language) => {
        const {
          locale_language_region: localeRegion,
          locale_language_code: id,
        } = language;
        if (localeRegion === region) {
          linksFound = true;
          return <LanguageLink path={path} linkItem={language} key={id} />;
        }
        return null;
      });
    }
    return links;
  };

  const regionLinks = getRegionLinks(localeLanguages);

  /**
   * Render.
   */
  return linksFound ? (
    <div className="region">
      <h3>{regionName}</h3>
      <ul>{regionLinks}</ul>
    </div>
  ) : null;
};
export default LanguageRegion;

/**
 * Language Links PropTypes.
 */
LanguageRegion.propTypes = {
  region: PropTypes.string,
};
