/**
 * Language Selector Button.
 * @format
 */

import React, { useContext } from 'react';
import { NavigationContext } from '../../context/navigation-context';
import { getCurrentLanguage } from '../language-services';
import { useStateValue } from '../../context/state-context';

const LanguageSelectorButton = () => {
  const navigationData = useContext(NavigationContext);
  const { locale_languages: localeLanguages } = navigationData;
  const currentLanguage = getCurrentLanguage(localeLanguages);
  const { language, flag } = currentLanguage;
  const flagSource = `/themes/custom/varian/webpack/components/language-selector/images/flags/${flag}.png`;
  const [{ updateToggle }, dispatch] = useStateValue();

  /**
   * Toggle Language Selector.
   */
  const toggleLanguageSelector = () => {
    dispatch({
      type: 'toggleLocale',
      updateLocale: {
        localeOpen: !updateToggle.localeOpen,
      },
    });
  };

  /**
   * Render.
   */
  return (
    <button
      type="button"
      className="language-select-button"
      onClick={() => toggleLanguageSelector()}>
      <img src={flagSource} alt={language} /> <span>{language}</span>
    </button>
  );
};
export default LanguageSelectorButton;
