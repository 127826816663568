/**
 * Contact Form Fields.
 * @format
 */
/* eslint-disable import/prefer-default-export */
import { contactCategoriesList } from '../lists/contact-categories';
import { specialtyList } from '../lists/specialty-list';
import { countryList } from '../lists/country-list';
import { stateList } from '../lists/state-list';
import { functionalRoles } from '../lists/functional-roles';

export const contactFormFields = [
  {
    fieldTitle: 'First name',
    fieldName: 'FirstName',
    id: 'first-name',
    type: 'text',
    placeholderText: 'Your first name here',
    errorMessage: 'First name field required.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
  {
    fieldTitle: 'Last name',
    fieldName: 'LastName',
    id: 'last-name',
    type: 'text',
    placeholderText: 'Your last name here',
    errorMessage: 'Last name field required.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
  {
    fieldTitle: 'Email address',
    fieldName: 'Email',
    id: 'email',
    type: 'email',
    placeholderText: 'name@website.com',
    errorMessage: 'Invalid email.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
  {
    fieldTitle: 'Company name',
    fieldName: 'Company',
    id: 'company',
    type: 'text',
    placeholderText: "Your company's name",
    errorMessage: 'Company name field required.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
  {
    fieldTitle: 'Functional role',
    fieldName: 'Functional_role__c',
    id: 'functional-role-select',
    type: 'select',
    placeholderText: 'Select a role',
    errorMessage: 'Functional role field required.',
    required: true,
    list: functionalRoles,
    useNameAsValue: true,
    valueCheck: [{ value: 'Physician' }],
    dependantFieldID: 'specialty-select',
  },
  {
    fieldTitle: 'Specialty',
    fieldName: 'Specialty__c',
    id: 'specialty-select',
    type: 'select',
    placeholderText: 'Select a specialty',
    errorMessage: 'Specialty field required.',
    required: true,
    list: specialtyList,
    useNameAsValue: true,
    dependantField: true,
  },
  {
    fieldTitle: 'Area of inquiry',
    fieldName: 'mktoAreaofInquiry',
    id: 'area-of-inquery-select',
    type: 'select',
    placeholderText: 'Select a contact',
    errorMessage: 'Area of inquiry field required.',
    required: true,
    list: contactCategoriesList,
    useNameAsValue: true,
  },
  {
    fieldTitle: 'Country',
    fieldName: 'Country',
    id: 'country-select',
    type: 'select',
    placeholderText: 'Select a country',
    errorMessage: 'Country field required.',
    required: true,
    list: countryList,
    useNameAsValue: true,
    valueCheck: [
      { value: 'United States' },
      { value: 'Canada' },
      { value: 'Australia' },
    ],
    dependantFieldID: 'state-select',
  },
  {
    fieldTitle: 'State or province',
    fieldName: 'State',
    id: 'state-select',
    type: 'select',
    placeholderText: 'Select a state or province',
    errorMessage: 'State or province field required.',
    required: true,
    list: stateList,
    useNameAsValue: true,
    dependantField: true,
  },
  {
    fieldTitle: 'Phone number',
    fieldName: 'Phone',
    id: 'phone',
    type: 'text',
    placeholderText: '(000) 000 0000',
    errorMessage: 'Phone number field required.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
  {
    fieldTitle: 'What are you contacting us about?',
    fieldName: 'mktoCommentCapture',
    id: 'comment',
    type: 'textarea',
    placeholderText:
      '"I have a question about TrueBeam..." or "I need support help..."',
    errorMessage: 'Reason for contact is required.',
    required: true,
    list: null,
    useNameAsValue: false,
  },
];
