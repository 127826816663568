/**
 * Image Services.
 *
 * This file houses any DOM manipulation of images like lazy loading.
 * @format
 */
/* eslint-disable import/prefer-default-export */
import lozad from 'lozad';

/**
 * Load Image Servies.
 */
export const loadImageServices = () => {
  /**
   * Lazy Load Images.
   */
  const lazyLoadImages = () => {
    const elements = document.querySelectorAll('.lazy-load');
    const observer = lozad(elements, {
      load(element) {
        element.classList.add('lazy-loaded');
      },
    });
    observer.observe();
  };
  lazyLoadImages();
};
