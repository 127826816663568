/**
 * Mobile Search.
 * @format
 */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from 'react';
import {
  safeTranslate,
  safeUrl,
  safeCurrentLanguage,
} from '../../../services/drupal-services';

/**
 * Mobile Search.
 */
const MobileSearch = () => {
  const placeholderText = safeTranslate('Search Varian');
  const ariaSearch = safeTranslate('Search through site content');
  const [searchTerm, setSearchTerm] = useState('');
  const searchIcon = safeUrl(
    '/themes/custom/varian/build/images/Search20px.png'
  );
  const currentLanguage =
    safeCurrentLanguage === 'en' ? '' : `${safeCurrentLanguage}/`;

  /**
   * Handle Input.
   */
  const handleKeyPress = (key) => {
    if (key === 'Enter') {
      const redirectUrl = safeUrl(
        `/${currentLanguage}search?search=${searchTerm}`
      );
      window.location = redirectUrl;
    }
  };

  /**
   * Render.
   */
  return (
    <div className="mobile-search-box">
      <input
        type="search"
        name="mobile-search"
        placeholder={placeholderText}
        aria-label={ariaSearch}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={(e) => handleKeyPress(e.key)}
      />
      <img className="search-icon" src={searchIcon} aria-hidden="true" />
    </div>
  );
};
export default MobileSearch;
