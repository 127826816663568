/**
 * Form Text Input.
 * @format
 */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */

import React from 'react';
import PropTypes from 'prop-types';
import { safeTranslate } from '../../../services/drupal-services';

/**
 * Form Text Input.
 *
 * @param {object} fieldProperties
 */
const FormTextInput = ({ fieldProperties, register, errors }) => {
  const {
    type,
    id,
    fieldTitle,
    fieldName,
    required,
    placeholderText,
    errorMessage,
  } = fieldProperties;
  const fieldClasses = errors[fieldName] ? 'error' : '';

  /**
   * Label.
   */
  const label = fieldTitle ? <label htmlFor="name">{fieldTitle}</label> : null;

  /**
   * Ref.
   */
  const ref = required ? register({ required: true }) : null;
  const emailRef = register({
    required: true,
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  });
  const textInputRef = type === 'email' ? emailRef : ref;

  /**
   * Field.
   */
  const field =
    type === 'textarea' ? (
      <textarea
        className={fieldClasses}
        id={id}
        name={fieldName}
        rows="5"
        placeholder={safeTranslate(placeholderText)}
        ref={ref}
      />
    ) : (
      <input
        className={fieldClasses}
        id={id}
        name={fieldName}
        type={type}
        placeholder={safeTranslate(placeholderText)}
        ref={textInputRef}
      />
    );

  /**
   * Error Message.
   */
  const error = errors[fieldName] && (
    <p className="error-message">
      <i className="fas fa-exclamation-circle" />
      {safeTranslate(errorMessage)}
    </p>
  );

  /**
   * Render.
   */
  return (
    <>
      {label}
      {field}
      {error}
    </>
  );
};
export default FormTextInput;

/**
 * Form Text Input PropTypes.
 */
FormTextInput.propTypes = {
  fieldProperties: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
};
