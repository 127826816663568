/** @format */

import React, { useContext } from 'react';
import { buildFooterLinks } from '../footer-link-builder';
import { NavigationContext } from '../../context/navigation-context';

/**
 * Footer Navigation.
 */
const FooterNavigation = () => {
  const navigationData = useContext(NavigationContext);
  const { footer_menu_vocab: footerMenuLinks } = navigationData;
  const footerMenu = footerMenuLinks
    ? buildFooterLinks(footerMenuLinks, true)
    : null;
  const footerClasses = footerMenuLinks ? 'footer-nav' : 'footer-nav hide';

  return (
    <nav role="navigation">
      <ul className={footerClasses}>{footerMenu}</ul>
    </nav>
  );
};
export default FooterNavigation;
