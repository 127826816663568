/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import ContactButton from '../forms/contact-form/contact-button.jsx';
import { safeUrl } from '../../services/drupal-services';

/**
 * Submenu.
 *
 * @param {array} linkList
 */
const Submenu = ({ linkList }) => {
  /**
   * Build Submenu.
   * @param {array} links
   */
  const buildSubmenu = (links) => {
    return links.map((currentLink) => {
      const { tid, name, link, new_window: newWindow, children } = currentLink;
      const isNewWindow = newWindow !== '0';
      if (tid === '93') {
        return <ContactButton name={name} key={tid} />;
      }
      return (
        <li key={tid}>
          <a
            href={safeUrl(link)}
            target={isNewWindow ? '_blank' : '_self'}
            rel={isNewWindow ? 'noreferrer noopener' : ''}>
            {name}
          </a>
          <Submenu linkList={children} />
        </li>
      );
    });
  };

  return linkList !== null ? <ul>{buildSubmenu(linkList)}</ul> : null;
};
export default Submenu;

Submenu.propTypes = {
  linkList: PropTypes.array,
};
