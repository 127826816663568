/**
 * Entry index for all JavaScript files to be used on the client facing varian
 * drupal 8 site.
 *
 * @format
 */

import './sass/external-styles.scss';
import '@babel/polyfill';
import { buildFooter } from './components/footer/footer-entry';
import { buildHeader } from './components/header/header-entry';
import { loadThirdPartyServices } from './services/third-party-integrations';
import { loadImageServices } from './services/image-services';
import { loadPolyfils } from './services/ie11-polyfils';

/**
 * DOM Loaded Events.
 */
document.addEventListener('DOMContentLoaded', () => {
  loadPolyfils();
  buildHeader();
  buildFooter();
  loadImageServices();
});

/**
 * Window Loaded Events.
 */
window.addEventListener('load', () => {
  loadThirdPartyServices();
});
