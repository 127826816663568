/** @format */
import React from 'react';
import ReactDOM from 'react-dom';
import Footer from './footer.jsx';

/**
 * Build Footer.
 */
// eslint-disable-next-line import/prefer-default-export
export const buildFooter = () => {
  const footer = document.getElementById('footer-menu');
  if (footer !== null) {
    ReactDOM.render(<Footer />, footer);
  }
};
