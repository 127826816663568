/**
 * Tracking Pixels.
 * Temporary tracking pixel loader until the tracking pixel module gets added.
 * @format
 */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-rest-params */

/**
 * Load Tracking Pixels.
 */
export const loadTrackingPixels = () => {
  /**
   * Current Page Check.
   * @param {string} relativeUrl
   */
  const currentPageCheck = (relativeUrl) => {
    const relativePath = window.location.pathname + window.location.search;
    if (relativePath === relativeUrl) {
      return true;
    }
    return false;
  };

  /**
   * gTag Script Loader.
   *
   * @param {array} tags
   */
  const gTagScriptLoader = (tags) => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'DC-5118829');

    if (tags.length > 0) {
      tags.forEach((tag) => {
        const { page, sendTo } = tag;
        if (page && sendTo) {
          if (currentPageCheck(page)) {
            gtag('event', 'conversion', {
              allow_custom_scripts: true,
              send_to: sendTo,
            });
          }
        }
      });
    }
  };

  /**
   * Load Google Tags.
   */
  const loadGoogleTags = () => {
    const googleTags = [
      {
        page:
          '/oncology/products/software/treatment-planning/rapidplan-knowledge-based-planning',
        sendTo: 'DC-5118829/ip16100/eclip000+standard',
      },
      {
        page: '/oncology/products/software/treatment-planning/eclipse-proton',
        sendTo: 'DC-5118829/ip16100/eclip001+standard',
      },
      {
        page:
          '/oncology/products/software/information-systems/aria-radiation-oncology',
        sendTo: 'DC-5118829/ip16100/ariai0+standard',
      },
      {
        page: '/oncology/products/software/varian-mobile',
        sendTo: 'DC-5118829/ip16100/varia0+standard',
      },
      {
        page: '/products/adaptive-therapy/ethos',
        sendTo: 'DC-5118829/ip16100/ethos0+standard',
      },
      {
        page:
          '/oncology/products/treatment-delivery/probeam-360-proton-therapy-system',
        sendTo: 'DC-5118829/ip161002/probe0+standard',
      },
      {
        page: 'oncology/products/software/velocity?cat=rapidsphere',
        sendTo: 'DC-5118829/ip16100/rapid0+standard',
      },
      {
        page: '/products/radiotherapy/treatment-delivery/halcyon',
        sendTo: 'DC-5118829/ip161000/halcy0+standard',
      },
    ];
    gTagScriptLoader(googleTags);
  };
  loadGoogleTags();
};
