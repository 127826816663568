/**
 * Submission Response.
 * @format
 */
/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { safeTranslate } from '../../../services/drupal-services';

/**
 * Submission Response.
 *
 * @param {bool} successfulSubmission
 */
const SubmissionResponse = ({ successfulSubmission, thankyouMessage }) => {
  const thankYou = safeTranslate('Thank you');
  const successMessage =
    thankyouMessage || safeTranslate('Your submission has been received.');
  const error = safeTranslate('Error');
  const errorMessage = safeTranslate(
    'There was a problem when sending your submission.  Please try again.'
  );
  const responseTitle = successfulSubmission ? thankYou : error;
  const responseDescription = successfulSubmission
    ? successMessage
    : errorMessage;
  const submitClasses = successfulSubmission
    ? 'submission-response success-message'
    : 'submission-response error-messsage';

  /**
   * Render.
   */
  return (
    <div className={submitClasses}>
      <h2>{responseTitle}</h2>
      <p dangerouslySetInnerHTML={{ __html: responseDescription }} />
    </div>
  );
};
export default SubmissionResponse;

/**
 * Submission Response PropTypes.
 */
SubmissionResponse.propTypes = {
  successfulSubmission: PropTypes.bool,
  thankyouMessage: PropTypes.string,
};
