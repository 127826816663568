/**
 * Drupal Services.
 * Custom checks for Drupal services.  Used to safely provide header and footer
 * to third party sites.
 *
 * @format
 */

/* eslint-disable import/prefer-default-export */

// Production
export const baseUrl = 'http://d8-prod.varian.com';

/**
 * Safe Current Language.
 */
export const safeCurrentLanguage =
  typeof drupalSettings !== 'undefined'
    ? drupalSettings.path.currentLanguage
    : '';

/**
 * Safe Language Prefix.
 */
export const safeLanguageUrlPrefix =
  typeof drupalSettings !== 'undefined' ? drupalSettings.path.pathPrefix : '';

/**
 * Safe URL.
 * @param {string} url
 */
export const safeUrl = (url) => {
  if (typeof url === 'undefined') {
    return '#';
  }
  if (url.indexOf('http') > -1) {
    return url;
  }
  return typeof Drupal !== 'undefined' ? url : `${baseUrl}${url}`;
};

/**
 * Safe Translate Drupal String.
 * @param {string} stringCheck
 */
export const safeTranslate = (stringCheck) => {
  return typeof Drupal !== 'undefined' ? Drupal.t(stringCheck) : stringCheck;
};
